<template lang="pug">
.thank
  .thank-wrap
    img(src="@/assets/anniversary_2023/thank/check.svg" alt="check")
    form.form
      p.text-center
        | 謝謝您提供的訊息，我們已經收到您的資料！
        br
        | 我們會盡快安排專員與您聯絡！
      .form-main
        h2 好禮二選一
        .btn-thank-group
          button(type="button" :disabled="fileName === 'childFile2'" @click="downloand(childFile1, 'childFile1')") 下載 兒童英文繪本有聲書
          button(type="button" :disabled="fileName === 'childFile1'" @click="downloand(childFile2, 'childFile2')") 下載 My Family 主題學習單
      button(type="button" @click="$router.push({name:'home'})") 返回WUWOW Jr官網
</template>

<script>
export default {

  name: 'ThankYouPage',

  data() {
    return {
      childFile1: 'https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book',
      childFile2: 'https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/Fast+guide/Junior_Worksheet_Family.pdf',
      fileName: '',
      body: null,
      script: null,
      noscript: null,
    };
  },
  // created() {
  //   this.body = document.querySelector('body');
  //   this.script = document.createElement('script');
  //   this.script.innerHTML = `
  //  		window._megoaa = window._megoaa || [];
  //        	window._megoaa.push({ type: 'event', name: 'wuwowjr_CV', acid: '26508', pxd: '854697138381854'});
  //  	`;
  //   this.noscript = document.createElement('noscript');
  //   this.noscript.innerHTML = `
  //  	<img src='//trace.popin.cc/api/bidder/track/pixel/conversion?cvn=wuwowjr_CV&acid=26508&pxd=854697138381854&tn=f9f2b1ef23fe2759c2cad0953029a94b' width='0' height='0' style='display:none' />
  //  	`;
  //   this.body.appendChild(this.script);
  //   this.body.appendChild(this.noscript);
  // },
  destroyed() {
    this.body.remove(this.script);
    this.body.remove(this.noscript);
  },
  methods: {
    downloand(file, type) {
      this.fileName = type;
      const a = document.createElement('a');
      a.setAttribute('href', file);
      a.setAttribute('download', '');
      a.setAttribute('target', '_blank');
      a.click();
      a.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 475px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin xxxl {
  @media (min-width: 1920px) {
    @content;
  }
}

.thank {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image:
    url('~@/assets/childrensday2024/thankbg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.thank-wrap {
  padding: 0 16px;
  text-align: center;

  img {
    margin-bottom: 40px;
  }

  @include md {
    padding: 0;
  }
}

.form {
  /* background: rgba(255, 255, 255, 0.4);
  border: 1px solid #fff;
  border-radius: 20px; */
  text-align: center;
  width: 100%;
  padding: 16px;

  @include lg {
    padding: 40px;
    width: 569px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    padding: 40px 0;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 15px;
    margin-bottom: 40px;
    color: #505050;
  }

  h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 1;
    margin-bottom: 20px;
  }

  &-main {
    background: #E1F8FF;
    border-radius: 10px;
    padding: 20px;
    @media (min-width: 1024px) {
      margin: 0 -25px;
    }
  }

  >button {
    background: transparent;
    border: 1px solid #0867F2;
    color: #0867F2;
    border-radius: 5px;
    width: 322px;
    outline: none;
    margin-top: 20px;
    padding: 15px 0;
    transition: all 0.3s;

    &:hover {
      background: #0867F2;
      color: white;
    }
  }
}

.btn-thank-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  button {
    flex-grow: 1;
    appearance: none;
    border: none;
    outline: none;
    background: #75CDEC;
    color: #fff;
    border-radius: 5px;
    padding: 15px 8px;
    @media (min-width: 1024px) {
      max-width: 50%;
    }

    &:hover {
      background: lighten(#75CDEC, 20%);
    }
    &:disabled {
      background: #EDECEA;
      color: #ADADAD;
    }
    &:hover:disabled {
      background: #EDECEA;
      color: #ADADAD;
    }
  }
}
</style>
