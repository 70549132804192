var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "thank"
  }, [_c('div', {
    staticClass: "thank-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/thank/check.svg"),
      "alt": "check"
    }
  }), _c('form', {
    staticClass: "form"
  }, [_vm._m(0), _c('div', {
    staticClass: "form-main"
  }, [_c('h2', [_vm._v("好禮二選一")]), _c('div', {
    staticClass: "btn-thank-group"
  }, [_c('button', {
    attrs: {
      "type": "button",
      "disabled": _vm.fileName === 'childFile2'
    },
    on: {
      "click": function ($event) {
        return _vm.downloand(_vm.childFile1, 'childFile1');
      }
    }
  }, [_vm._v("下載 兒童英文繪本有聲書")]), _c('button', {
    attrs: {
      "type": "button",
      "disabled": _vm.fileName === 'childFile1'
    },
    on: {
      "click": function ($event) {
        return _vm.downloand(_vm.childFile2, 'childFile2');
      }
    }
  }, [_vm._v("下載 My Family 主題學習單")])])]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'home'
        });
      }
    }
  }, [_vm._v("返回WUWOW Jr官網")])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-center"
  }, [_vm._v("謝謝您提供的訊息，我們已經收到您的資料！"), _c('br'), _vm._v("我們會盡快安排專員與您聯絡！")]);

}]

export { render, staticRenderFns }